import React, { FC } from 'react';
import Layout from 'components/blog/layout';
import { Topic, BlogPostItem } from '../Types';
import PostList from './list';

interface IProps {
  pathContext: {
    posts: ReadonlyArray<BlogPostItem>;
    topics: ReadonlyArray<Topic>;
  };
  location: {
    pathname: string;
  };
}

const Blog: FC<IProps> = ({ pathContext }) => (
  <Layout topics={pathContext.topics}>
    <section className="section">
      <div className="inner">
        <PostList list={pathContext.posts} pathname="/blog/" />
      </div>
    </section>
  </Layout>
);

export default Blog;
